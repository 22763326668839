import React, { useState, useEffect } from "react";
import EstimateCard from "./EstimateCard";
import CostEstimator from "./CostEstimator";
import "./CostEstimator.css";
import left from "../../assets/icons/left.svg";
import { toTitleCase } from "../../utils/index.js";
import locationIcon from "../../assets/icons/ep_location.svg";
import phone from "../../assets/icons/phone.svg";
import hours from "../../assets/icons/hours.svg";
import star from "../../assets/icons/star.svg";
import filledStar from "../../assets/icons/filledStar.svg";
import { extractFeeText, renderEstimatedCost } from "./utils";

function EstimateDetails({ selectedEstimate, setSelectedEstimate }) {
  const handleBackClick = () => {
    setSelectedEstimate(null);
  };

  const StarRating = (props) => {
    if (props.rating == null) {
      return <div className="star-rating">Rating unavailable</div>;
    }
    return (
      <div className="star-rating">
        {[...Array(5)].map((item, idx) => {
          return (
            <img
              key={idx}
              className="star"
              src={idx < props.rating ? filledStar : star}
            />
          );
        })}
      </div>
    );
  };

  function WhereYoullGo({ selectedEstimate }) {
    if (selectedEstimate) {
      const details = selectedEstimate.institutional_info.details;
      var facilityAddress = `${details.line_one} ${details.city}, ${details.state} ${details.zip_code}`;
      var map_url =
        "https://www.google.com/maps/embed/v1/place?key=" +
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
        "&q=" +
        facilityAddress;
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    function formatPhoneNumber(phoneNumberArray) {
      const phoneNumber = phoneNumberArray.join("");
      const formattedPhoneNumber = `(${phoneNumber.slice(
        0,
        3
      )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
      return formattedPhoneNumber;
    }

    return (
      <div
        className={`${
          !selectedEstimate ? "" : "facility-estimate__estimate-section"
        } where-youll-be`}
        style={{ border: "1px solid black" }}
      >
        <div className="facility-estimate__facility-details">
          <iframe
            width="100%"
            height="350"
            style={{ marginBottom: "24px", border: "1px solid #ccc" }}
            loading="lazy"
            allowFullScreen
            src={map_url}
          ></iframe>
          <div
            className="facility-estimate__facility-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div
              className="facility-estimate__facility-box"
              style={{ display: "flex" }}
            >
              <img src={locationIcon} />
              <p className="facility-estimate__facility-detail">
                {selectedEstimate.institutional_info.details.line_one}
                {", "}
                {selectedEstimate.institutional_info.details.city}
                {", "}
                {selectedEstimate.institutional_info.details.state}
                {", "}
                {selectedEstimate.institutional_info.details.zip_code}
                <br />
              </p>
            </div>
            {selectedEstimate.institutional_info.details.phone_numbers.length > 0 &&
             <div
             className="facility-estimate__facility-box"
             style={{ display: "flex" }}
           >
             <img src={phone} />
             <p className="facility-estimate__facility-detail">
               {formatPhoneNumber(
                 selectedEstimate.institutional_info.details.phone_numbers
               )}
             </p>
           </div>
            }

          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="estimate-details-container">
      <div className="header-estimate-details" cursor>
        <p
          className="back-button-estimateDetails"
          onClick={handleBackClick}
          style={{ cursor: "pointer" }}
        >
          <img src={left} /> BACK{" "}
        </p>
      </div>
      {selectedEstimate.professional_info.should_render && (
             <div className="estimate-box">
             <div className="estimate-details-title">Who you'll see</div>
             <div className="provider-description-section">
               <p className="estimates-titles-container">
                 Provider
                 <p className="doctor-description">
                   Dr.{" "}
                   {toTitleCase(
                     selectedEstimate.professional_info.details.first_name
                   )}{" "}
                   {toTitleCase(
                     selectedEstimate.professional_info.details.last_name
                   )}
                 </p>
                 <p className="doctor-description">NPI: {selectedEstimate?.professional_info?.details?.npi || 'N/A'}</p>
               </p>
               <p className="fee-container">
                 {" "}
                 Fee
                 <p className="doctor-description">
                   {" "}
                   <b>Cost: </b>
                   {extractFeeText(selectedEstimate?.professional_info?.professional_fee)}
                   <br/>
                   {renderEstimatedCost("out_of_pocket_cost", selectedEstimate
                     ?.professional_info
                     ?.professional_fee)}
                   {renderEstimatedCost("insurance_coverage", selectedEstimate
                     ?.professional_info
                     ?.professional_fee)}
                 </p>
               </p>
             </div>
     
             <div className="facility-info-estimate-details">
               {selectedEstimate.professional_info.details.specialties.map(
                 (specialty, idx) => (
                   <p key={idx} className="provider-specialty">
                     {specialty?.display}
                   </p>
                 )
               )}
               <hr className="solid" />
             </div>
             <div>
               <p>
                 Overall Rating
                 <StarRating
                   rating={selectedEstimate.professional_info.ratings?.overall_score}
                 />
               </p>
               <p>
                 Quality Rating
                 <StarRating
                   rating={selectedEstimate.professional_info.ratings?.quality_score}
                 />
               </p>
               <p>
                 Efficiency Rating
                 <StarRating
                   rating={
                     selectedEstimate.professional_info.ratings?.efficiency_score
                   }
                 />
               </p>
             </div>
           </div>

      )}
 
      {selectedEstimate.institutional_info.should_render && (
        <div className="estimate-box">
        <div className="estimate-details-title">Where You'll Be</div>
        <div className="facility-description-section">
          <p className="estimates-titles-container">
            Facility
            <p className="doctor-description">
              {selectedEstimate.institutional_info.details.name ? toTitleCase(selectedEstimate.institutional_info.details.name) : 'N/A'}
            </p>
            <p className="doctor-description">NPI: {selectedEstimate?.institutional_info?.details?.npi || 'N/A'}</p>
          </p>
          <p className="fee-container">
            Fee
            <p className="doctor-description">
            {" "}
            <b>Cost: </b>
            {extractFeeText(selectedEstimate?.institutional_info?.institutional_fee)}
            <br/>
            {renderEstimatedCost("out_of_pocket_cost", selectedEstimate
              ?.institutional_info
              ?.institutional_fee)}
            {renderEstimatedCost("insurance_coverage", selectedEstimate
              ?.institutional_info
              ?.institutional_fee)}
            </p>
          </p>
        </div>
        <div className="facility-info-estimate-details">
          Facility Type
          <p className="facility-specialty">
            {
              selectedEstimate.institutional_info.details.place_of_service
                ?.display
            }
          </p>
          <div>
            <WhereYoullGo selectedEstimate={selectedEstimate} />
            <div>
              <p>
                {" "}
                Overall Rating
                <StarRating
                  rating={
                    selectedEstimate.institutional_info.ratings?.overall_score
                  }
                />{" "}
              </p>

              <p>
                Experience Rating
                <StarRating
                  rating={
                    selectedEstimate.institutional_info.ratings
                      ?.experience_score
                  }
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      )}
      
    </div>
  );
}

export default EstimateDetails;
