const userConfig = JSON.parse(process.env.REACT_APP_USER_CONFIG);
const featureFlags = {
    // These are based on a user's role
    // A user can have a standard role, admin 
    // role, or external (client) role

    // External role users will only see
    // the cost estimator page, and will
    // have locked down plans and API keys
    // that they can't edit.
    external: (userEmail) => {
        const userSpecificConfig = userConfig[userEmail] || {};
        return {
            allowedRoutes: ["/cost-estimator"],
            hideOONTags: true,
            allowedPlans: userSpecificConfig.plans || [],
            allowedApiKeys: userSpecificConfig.apiKeys || []
        };
    },
}

export default featureFlags;